import { ReactNode } from "react";
import classnames from "classnames";
import { addDays, isToday, isFuture } from "date-fns";

import { useQuery } from "@apollo/client";
import { useTranslation } from "src/translations/translationProvider";
import { isMobile, Desktop } from "src/components/responsive";
import Card from "src/components/card";
import ValueUnit from "src/components/valueUnit";
import { LoadingChart } from "src/components/chart";
import SpotPriceCard from "./spotPriceCard";
import SpotPriceChart from "./spotPriceChart";
import { useGetCurrentUser } from "src/modules/userProvider";

import styles from "./latestSpotPrices.module.scss";
import dashboardStyles from "../dashboard.module.scss";
import globalStyles from "src/styles/global.module.scss";
import {
  GetLatestSpotPricesDocument,
  SpotPriceArea,
  Weather,
} from "src/graphql/graphql";

const DEFAULT_SPOTPRICE_AREAS = ["SYS", "SE1", "SE2", "SE3", "SE4"];

function getSortedPriceAreas(spotPriceAreas: Array<SpotPriceArea>) {
  const sorted = spotPriceAreas.slice();
  sorted.sort((a, b) => {
    if (a.area < b.area) {
      return -1;
    }
    if (a.area > b.area) {
      return 1;
    }

    return 0;
  });

  // Make sure "SYS" comes first
  if (sorted.length > 1) sorted.unshift(sorted.pop()!);

  return sorted;
}

function PriceContainer({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <div className={styles.wrapper}>
      <h2
        className={classnames(
          globalStyles.sectionTitle,
          dashboardStyles.sectionTitle,
        )}
      >
        {title}
      </h2>
      <div>{children}</div>
    </div>
  );
}

function LatestSpotPriceCard({
  spotPrice,
  weather,
  isLoading,
  index,
}: {
  spotPrice: Omit<SpotPriceArea, "__typename">;
  weather: Weather | null;
  isLoading: boolean;
  index: number;
}) {
  const { area, areaDescription, currency, spotPrices } = spotPrice;
  const filteredSpotPrices = spotPrices.filter(({ time }) => {
    const date = new Date(time);
    return isToday(date) || isFuture(date);
  });

  return (
    (Boolean(spotPrice) || isLoading) && (
      <SpotPriceCard
        isLoading={isLoading}
        key={index}
        titleClass={classnames({
          [styles.sysPriceColor!]: area === "SYS",
        })}
        area={`${area} ${areaDescription || ""}`}
        unit={currency}
        spotPrices={filteredSpotPrices}
        testDataId={`spotprice-${area}`}
        weather={weather}
      />
    )
  );
}

function ErrorCard({ title, message }: { title: string; message: string }) {
  return (
    <div className={styles.spotPriceCards}>
      <Card
        title={
          <div
            className={classnames(styles.header, {
              [globalStyles.sectionTitleLarge!]: isMobile(),
            })}
          >
            {title}
          </div>
        }
        slotOne={
          <div className={styles.cardContent}>
            <p className={styles.title}>{message}</p>
            <ValueUnit value={""} unit={""} vertical />
          </div>
        }
      />
    </div>
  );
}

export function LatestSpotPrices() {
  const user = useGetCurrentUser();
  const { translate } = useTranslation();
  const now = new Date();

  const { data, loading, error } = useQuery(GetLatestSpotPricesDocument, {
    variables: {
      organizationId: user.selectedOrganizationConnection.organization.id,
    },
  });

  if (loading) {
    return (
      <PriceContainer title={translate("Market")}>
        <div className={styles.spotPriceContainer}>
          <Desktop>
            <LoadingChart />
          </Desktop>
        </div>
        <div className={styles.spotPriceCards}>
          {DEFAULT_SPOTPRICE_AREAS.map((_area, i) => {
            const placeholder: SpotPriceArea = {
              __typename: "SpotPriceArea",
              area: "...",
              areaDescription: "",
              currency: "",
              spotPrices: [
                {
                  __typename: "SpotPrice",
                  price: 0,
                  time: addDays(now, 1).toString(),
                },
                {
                  __typename: "SpotPrice",
                  price: 0,
                  time: now.toString(),
                },
              ],
            };
            return (
              <LatestSpotPriceCard
                key={i}
                index={i}
                spotPrice={placeholder}
                weather={null}
                isLoading={true}
              />
            );
          })}
        </div>
      </PriceContainer>
    );
  }

  if (error || !data || data.organization.spotPriceAreas.length === 0) {
    return (
      <PriceContainer title={translate("Market")}>
        <ErrorCard
          title={translate("Market data")}
          message={translate("Cannot get Market data")}
        />
      </PriceContainer>
    );
  }

  const {
    priceAreasWeatherToday,
    organization: { spotPriceAreas },
  } = data;

  const sortedPriceAreas = getSortedPriceAreas(spotPriceAreas);

  return (
    <PriceContainer title={translate("Market")}>
      {" "}
      <div className={styles.spotPriceContainer}>
        <Desktop>
          <SpotPriceChart spotPriceAreas={sortedPriceAreas} />
        </Desktop>
      </div>
      <h2
        className={classnames(
          globalStyles.sectionTitle,
          dashboardStyles.sectionTitle,
        )}
      >
        {translate("Prices")}
      </h2>
      <div>
        <div className={styles.spotPriceCards}>
          {sortedPriceAreas.map((spotPrice: SpotPriceArea, i: number) => (
            <LatestSpotPriceCard
              key={i}
              index={i}
              spotPrice={spotPrice}
              weather={
                priceAreasWeatherToday?.find(
                  (pa) => pa && pa.code === spotPrice.area,
                ) ?? null
              }
              isLoading={false}
            />
          ))}
        </div>
      </div>
    </PriceContainer>
  );
}
