import { SVGProps, Ref, forwardRef, memo } from "react";
const SvgDelete = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path d="M9.714 9.786v6.428c0 .201-.156.357-.357.357h-.714a.353.353 0 0 1-.357-.357V9.786c0-.201.156-.357.357-.357h.714c.201 0 .357.156.357.357Zm2.857 0v6.428c0 .201-.156.357-.357.357H11.5a.353.353 0 0 1-.357-.357V9.786c0-.201.156-.357.357-.357h.714c.201 0 .357.156.357.357Zm2.858 0v6.428c0 .201-.157.357-.358.357h-.714a.353.353 0 0 1-.357-.357V9.786c0-.201.156-.357.357-.357h.714c.201 0 .358.156.358.357Zm1.428 8.08V7.286h-10v10.58c0 .536.301.848.357.848H16.5c.056 0 .357-.312.357-.848Zm-7.5-12.009h5l-.536-1.306a.43.43 0 0 0-.19-.122h-3.537a.376.376 0 0 0-.19.122l-.547 1.306Zm10.357.357v.715c0 .2-.156.357-.357.357h-1.071v10.58c0 1.228-.804 2.277-1.786 2.277H7.214c-.982 0-1.785-1.005-1.785-2.232V7.286H4.357A.353.353 0 0 1 4 6.929v-.715c0-.2.156-.357.357-.357h3.449l.781-1.864C8.81 3.446 9.48 3 10.071 3h3.572c.591 0 1.261.446 1.484.993l.781 1.864h3.45c.2 0 .356.156.356.357Z" />
  </svg>
);
const ForwardRef = forwardRef(SvgDelete);
const Memo = memo(ForwardRef);
export { Memo as DeleteIcon };
