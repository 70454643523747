import { useTranslation } from "src/translations/translationProvider";
import Chart, {
  PlaceholderChart,
  PlaceholderChartText,
} from "src/components/chart";
import { CogenerationPlanGraph} from "../cogenerationPlanModel";
import { createChartDataset } from "./createChartDataset";
import { createChartOptions } from "./createChartOptions";

export function CogenerationPlanChart({
  cogenerationPlans,
  className,
}: {
  cogenerationPlans: CogenerationPlanGraph[] | undefined;
  className?: string;
}) {
  const { translate } = useTranslation();

  if (!cogenerationPlans) {
    return (
      <PlaceholderChart
        className={className}
        key="cogeneration plan chart error"
      >
        <PlaceholderChartText>
          {translate("No data found")}
        </PlaceholderChartText>
      </PlaceholderChart>
    );
  }

  return (
    <Chart
      className={className}
      data={createChartDataset({ cogenerationPlans, translate })}
      options={createChartOptions({ translate })}
    />
  );
}
