import { LanguageKey } from "src/config/translations/translationsTypes";
import { useTranslation } from "src/translations/translationProvider";

/**
 * Deprecated since it is not a component, use the translate function from the hook instead:
 * const { translate } = useTranslation()
 * <div>{translate("translated string")}</div>
 * @deprecated
 */
export function Translate({ children }: { children: string }) {
  const { translate } = useTranslation();
  return translate(children as LanguageKey);
}
