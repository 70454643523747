import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  AuthenticationProvider,
  AuthenticationRequired,
  ApolloWithAuth,
} from "/src/auth/authenticationStrategies";
import AuthCallback from "../../auth/authCallback";
import Error, { ErrorBoundary } from "/src/components/error";
import { TranslationProvider } from "src/translations/translationProvider";

import initializeAnalytics from "/src/analytics.js";
import ApplicationRoutes from "./applicationRoutes";
import { NotificationProvider } from "src/modules/notifications/notificationsProvider";
import { UserProvider } from "src/modules/userProvider";
import { createTheme, MantineProvider } from "@mantine/core";

initializeAnalytics();
const theme = createTheme({
  spacing: {
    xs: "0.5rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
  },
  colors: {
    modityOrange: [
      "#fff4e3",
      "#ffe7cf",
      "#facea0",
      "#f6b36e",
      "#f29c43",
      "#f08d28",
      "#f08618",
      "#d6730b",
      "#bf6503",
      "#a65600",
    ],
  },
});

const App = () => (
  <AuthenticationProvider>
    <TranslationProvider>
      <MantineProvider theme={theme}>
        <ErrorBoundary>
          <Helmet>
            <title>Modity Energy Trading AB</title>
          </Helmet>
          <BrowserRouter>
            <Routes>
              <Route path="/auth" element={<AuthCallback />} />
              <Route
                path="/error"
                element={<Error errorString={window.location.hash} />}
              />
              <Route
                path="/*"
                element={
                  <AuthenticationRequired>
                    <ApolloWithAuth>
                      <NotificationProvider>
                        <UserProvider>
                          <ApplicationRoutes />
                        </UserProvider>
                      </NotificationProvider>
                    </ApolloWithAuth>
                  </AuthenticationRequired>
                }
              />
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </MantineProvider>
    </TranslationProvider>
  </AuthenticationProvider>
);

export default App;
