import { LanguageKey } from "src/config/translations/translationsTypes";
import { CogenerationPlanGraph } from "../cogenerationPlanModel";

export function createChartDataset({
  cogenerationPlans,
  translate,
}: {
  cogenerationPlans: CogenerationPlanGraph[];
  translate: (value: LanguageKey) => string;
}) {

  const maxY = Math.max(
    ...cogenerationPlans
      .flatMap((plan) => [plan.production, plan.consumption])
      .filter((value) => value !== null && value !== undefined)
  );

  return {
    datasets: [
      {
        label: translate("Production") + " (MW)",
        fill: false,
        borderColor: "#25a772",
        borderWidth: "2",
        backgroundColor: "#25a772",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y",
        spanGaps: false,
        data: cogenerationPlans.flatMap((plan) => [
          {
            x: new Date(plan.fromTimestamp),
            y: plan.production,
          },
          {
            x: new Date(plan.toTimestamp),
            y: plan.production,
          },
        ]),
      },
      {
        label: translate("Consumption") + " (MW)",
        fill: false,
        borderColor: "#f08517",
        borderWidth: "2",
        backgroundColor: "#f08517",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y",
        spanGaps: false,
        data: cogenerationPlans.flatMap((plan) => [
          {
            x: new Date(plan.fromTimestamp),
            y: plan.consumption,
          },
          {
            x: new Date(plan.toTimestamp),
            y: plan.consumption,
          },
        ]),
      },
      {
        label: translate("Missing hour"),
        fill: true,
        borderColor: "rgba(255, 0, 0, 0.5)",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        data: cogenerationPlans
          .flatMap((range) => [
            { x: new Date(range.fromTimestamp), y: range.consumption === null || range.production === null ? maxY: 0 },
            { x: new Date(range.toTimestamp), y: range.consumption === null || range.production === null ? maxY: 0 }, 
          ]),
        spanGaps: false,
      },
    ],
  };
}