import { useTranslation } from "src/translations/translationProvider";
import styles from "./excelValidationError.module.scss";
import { ParsedExcelError } from "src/utilities/parseExcel";
import { MissingHourRange } from "src/modules/app/customerdata/incomingDataPlans/cogenerationPlanModel";

export function ExcelValidationErrors({
  errors,
  missingHours,
}: {
  errors: ParsedExcelError[];
  missingHours: MissingHourRange[];
}) {
  const { translate, formatDate } = useTranslation();
  return (
    <div className={styles.errorSection}>
      {errors?.length > 0 && (
        <>
          <h3>{translate("ExcelValidationError")}</h3>
          {errors.map((error) => (
            <div className={styles.error} key={`${error.row}-${error.column}`}>
            {`  
              ${translate("row")}: ${error.row + 1}\n 
              ${translate("column")}: ${error.column}\n
              ${translate("value")}: ${error.value}\n
              ${translate("error message")}: ${translate(error.message)}
            `}
          </div>
          ))}
        </>
      )}
  
      {missingHours?.length > 0 && (
        <>
          <h3>{translate("MissingHoursError")}</h3>
          {translate("Missing hour")}:
          {missingHours.map((missingHour, i) => (
            <div className={styles.error} key={i}>
              {formatDate(Date.parse(missingHour.fromTimestamp), "yyyy-MM-dd HH:mm")} - {formatDate(Date.parse(missingHour.toTimestamp), "yyyy-MM-dd HH:mm")}
            </div>
          ))}
        </>
      )}
    </div>
  );
}
