import React, { useState } from "react";
import classNames from "classnames";

import { SearchIcon } from "src/icons";

import styles from "./search.module.scss";
import { TextInput, TextInputProps } from "@mantine/core";

export function Search({
  defaultValue,
  onChange,
  onSubmit,
  className,
  buttonClassName,
  ...rest
}: TextInputProps & {
  buttonClassName?: string;
  onSubmit?: (value: TextInputProps["defaultValue"]) => void;
}) {
  const [state, setState] = useState({ value: defaultValue ?? "" });

  const { value } = state;

  return (
    <form
      className={classNames(styles.search, className)}
      onSubmit={(e) => {
        e.preventDefault();
        if (onSubmit) {
          onSubmit(value);
        }
      }}
    >
      <TextInput
        variant="unstyled"
        styles={{
          input: {
            fontFamily: "inherit",
          },
        }}
        className={styles.inputRoot}
        classNames={{
          input: styles.input,
        }}
        value={value}
        size="md"
        onChange={(e) => {
          const value = e.target.value;
          setState({ value });
          if (onChange) {
            onChange(e);
          }
        }}
        maxLength={40}
        {...rest}
      />
      <button
        type="submit"
        className={classNames(styles.iconWrapper, buttonClassName)}
      >
        <SearchIcon />
      </button>
    </form>
  );
}
