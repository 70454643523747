import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import "chartjs-adapter-date-fns";
import {
  Chart as ChartJS,
  LinearScale,
  TimeScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  LineController,
  BarController,
  Filler
} from "chart.js";
import { Chart as RCChart } from "react-chartjs-2";

import classNames from "classnames";
import { useTranslation } from "src/translations/translationProvider";
import { Translate } from "src/translations/translate";
import { MultiSelect } from "/src/components/select";
import createOptions from "./chartOptions";
import CopyButton from "/src/components/copyButton";

import styles from "./chart.module.scss";
import { useNotifications } from "src/modules/notifications/notificationsProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  BarElement,
  LineController,
  BarController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Chart = ({
  className,
  type,
  data,
  options,
  style,
  maxNumberOfVisibleDatasets = 6,
  enableCopy = false,
}) => {
  const { locale } = useTranslation();
  const { translate } = useTranslation();
  const { showNotification } = useNotifications()

  let selected = data.datasets.slice(0, maxNumberOfVisibleDatasets);
  const [hideInChart, setHideInChart] = useState([]);
  const canvasRef = useRef(null);

  options.locale = locale;

  const getSelectedData = () => {
    const selectedDatasets =
      (selected && selected.map((sel) => sel.label)) || [];
    const datasets = data.datasets.filter((dataset) =>
      selectedDatasets.includes(dataset.label)
    );
    const newData = { ...data };
    newData.datasets = datasets;
    return newData;
  };

  const toggleHideInChart = (label) => {
    let newList = [];

    if (hideInChart.includes(label)) {
      newList = hideInChart.filter((item) => item !== label);
    } else {
      newList = [...hideInChart, label];
    }

    setHideInChart(newList);
  };

  const getDataSetsForChart = () => {
    const selectedData = getSelectedData(selected);

    const datasets = selectedData.datasets.filter(
      (dataset) => !hideInChart.includes(dataset.label)
    );
    const newData = { ...data };
    newData.datasets = datasets;
    return newData;
  };

  const getDataSetsForHeader = () => {
    const selectedData = getSelectedData(selected);

    return selectedData.datasets.map(({ label, backgroundColor }) => (
      <div
        data-test-id={`charts-${label}`}
        key={label}
        onClick={() =>
          options.shouldDisableInteraction ? {} : toggleHideInChart(label)
        }
        className={classNames(styles.headerLabel, {
          [styles.disabled]: hideInChart.includes(label),
          [styles.disableInteraction]: options.shouldDisableInteraction,
        })}
      >
        <span className={styles.circle} style={{ backgroundColor }} />
        {label}
      </div>
    ));
  };

  const getOptionsForSelect = () => {
    return data.datasets.map(({ label, backgroundColor }) => {
      return {
        value: label,
        label: (
          <div>
            <span style={{ color: backgroundColor }}>&#9679;</span> {label}
          </div>
        ),
      };
    });
  };

  const getSelectedValues = () =>
    selected.map((value) => {
      return {
        value: value.label,
      };
    });

  const setSelectedDatasets = (values) => {
    const mappedValues = values.map((value) => value.value);
    setHideInChart(hideInChart.filter((item) => mappedValues.includes(item)));
    selected = data.datasets.filter((ds) => mappedValues.includes(ds.label));
  };

  const infoNode = (
    <div>
      <Translate>Select max</Translate> {maxNumberOfVisibleDatasets}
    </div>
  );

  const copyToClipboard = () => {
    if (canvasRef.current) {
      showNotification(translate("Copied chart"));
      canvasRef.current.canvas.toBlob((blob) => {
        navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
      }, "image/png");
    }
  };

  return (
    <div className={classNames(styles.chart, className)} style={style}>
      <div className={styles.header}>
        <div>
          {getDataSetsForHeader()}
          {data.datasets.length > maxNumberOfVisibleDatasets && (
            <MultiSelect
              infoNode={infoNode}
              wrapperClassName={styles.headerLabel}
              maxSelections={maxNumberOfVisibleDatasets}
              value={getSelectedValues()}
              options={getOptionsForSelect()}
              onChange={setSelectedDatasets}
            />
          )}
        </div>
        <div>
          {enableCopy && (
            <CopyButton
              className={styles.copyButton}
              onClick={copyToClipboard}
            />
          )}
        </div>
      </div>
      <div
        data-test-id="chart"
        className={classNames(
          styles.chartCanvas,
          options.classNameForChartCanvas,
          {
            [styles.multipleYAxes]: options.yAxes && options.yAxes.length > 1,
          }
        )}
      >
        <RCChart
          ref={canvasRef}
          type={String(type).toLowerCase()}
          key={locale}
          data={getDataSetsForChart()}
          options={createOptions(options)}
        />
      </div>
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["Line", "Bar"]),
  headerElement: PropTypes.element,
  data: PropTypes.shape({
    datasets: PropTypes.array,
  }),
  options: PropTypes.object,
  classNameForChartCanvas: PropTypes.string,
  style: PropTypes.object,
  maxNumberOfVisibleDatasets: PropTypes.number,
  enableCopy: PropTypes.bool,
};

Chart.defaultProps = {
  type: "Line",
  data: {
    datasets: [],
  },
  options: {},
};

export default Chart;
