import { formatNumber } from "/src/utilities";
import { sv, enGB } from "date-fns/locale";

const timeFormat = "MM/DD/YYYY HH:mm";
const palette = {
  black: "#04101c",
  darkGrey: "#8c8e91",
};

const createOptions = ({
  yLabel = "",
  xMin = null,
  xMax = null,
  y: customY,
  y1: customY1,
  x: customX,
  xAxisType = "time",
  locale = "sv-SE",
  titleCallback = (tooltipItems) =>
    tooltipItems[0] ? tooltipItems[0].label : "",
  unit = "day",
  title,
  tooltipMode = "index",
}) => {
  const options = {
    responsive: true,
    interaction: {
      mode: tooltipMode,
      axis: tooltipMode === "nearest" ? "x" : undefined,
    },
    maintainAspectRatio: false,
    aspectRatio: 3,
    layout: {
      padding: {
        left: 16,
        right: 16,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      title: {
        display: !!title,
        text: title,
        font: { size: 16 },
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: tooltipMode,
        position: "nearest",
        backgroundColor: "#FFF",
        padding: 16,
        bodySpacing: 10,
        borderWidth: 1,
        borderColor: "#000",
        bodyColor: "#000",
        titleMarginBottom: 20,
        titleColor: "#000",
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.dataset.label;
            return !label || isNaN(tooltipItem.parsed.y)
              ? ""
              : `${label}: ${formatNumber(
                  tooltipItem.parsed.y,
                  locale
                )} ${yLabel}`;
          },
          title: titleCallback,
        },
      },
    },
    scales: {
      x: {
        adapters: {
          date: {
            locale: locale === "sv-SE" ? sv : enGB,
          },
        },
        offset: true,
        type: xAxisType,
        time: {
          parser: timeFormat,
          tooltipFormat: "yyyy-MM-dd HH:mm",
          unit,
          displayFormats: {
            hour: "yyyy-MM-dd HH:mm",
            month: "yyyy-MM",
          },
        },
        title: {
          display: false,
        },
        ticks: {
          color: palette.darkGrey,
          font: {
            size: 14,
            family: "AcuminPro",
            padding: 8,
          },
        },
        max: xMax,
        min: xMin,
        grid: {
          tickLength: 0,
          color: "rgba(0, 0, 0, 0.05)",
        },
      },
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        ticks: {
          color: palette.black,
          font: {
            size: 14,
            family: "AcuminPro",
            padding: 12,
          },
        },
        title: {
          display: true,
          text: yLabel,
        },
        grid: {
          tickLength: 0,
        },
      },
    },
  };

  return customY1
    ? { ...options, scales: { y: customY, y1: customY1, x: customX ? customX : options.scales.x } }
    : options;
};

export default createOptions;
