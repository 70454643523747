import { LanguageKey } from "src/config/translations/translationsTypes";

export function createChartOptions({
  translate,
}: {
  translate: (value: LanguageKey) => string;
}) {
  
  return {
    unit: "hour",
    y: {
      type: "linear",
      position: "left",
      beginAtZero: true,
      suggestedMin: 0,
      title: {
        display: true,
        text: translate("Production") + "/" + translate("Consumption") + "(MW)",
      },
    },
  };
}
