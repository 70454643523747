import { Helmet } from "react-helmet";
import { PageLayout } from "src/components/pageLayout/pageLayout";
import { CogenerationPlan } from "src/modules/app/customerdata/incomingDataPlans/cogenerationPlan";
import { useGetCurrentUser } from "src/modules/userProvider";
import { useTranslation } from "src/translations/translationProvider";
export function CustomerData() {
  const { translate } = useTranslation();
  const currentUser = useGetCurrentUser();

  return (
    <section>
      <Helmet>
        <title>{`Modity - ${translate("Menu_CustomerData")}`}</title>
      </Helmet>
      <PageLayout title={translate("Heading_CustomerData")}>
        <CogenerationPlan organizationId={currentUser.selectedOrganizationConnection.organization.id}/>
      </PageLayout>
    </section>
  );
}
