import {
  isNumber,
  isValidDate,
  ParsedExcel,
  ValidateParsedExcel,
} from "src/utilities/parseExcel";
import { CogenerationData, CogenerationPlanInput, MissingHourRange, ParsedExcelError } from "./cogenerationPlanModel";
import { parseISO, addHours, formatISO, differenceInHours } from "date-fns";

export function validateParsedCogenerationPlanExcel(
  parsedExcel: ParsedExcel<CogenerationData>,
): {
  errors: ParsedExcelError[];
  values: CogenerationPlanInput[];
} {
  return ValidateParsedExcel(parsedExcel, validateRowAndCreateErrors);
}

function validateRowAndCreateErrors(
  row: ParsedExcel<CogenerationData>["data"][0],
): CogenerationPlanInput | ParsedExcelError[] {
  const errors: ParsedExcelError[] = [];
  if (!isValidDate(row["A"])) {
    errors.push({
      column: "A",
      row: row.__RowNum__,
      value: row["A"],
      message: "invalid date format",
    });
  }
  if (!isValidDate(row["B"])) {
    errors.push({
      column: "B",
      row: row.__RowNum__,
      value: row["B"],
      message: "invalid date format",
    });
  }
  if (row.C != null && !isNumber(row.C)) {
    errors.push({
      column: "C",
      row: row.__RowNum__,
      value: row.C.toString(),
      message: "invalid number value",
    });
  }
  if (row["D"] != null && !isNumber(row["D"])) {
    errors.push({
      column: "D",
      row: row.__RowNum__,
      value: row["D"].toString(),
      message: "invalid number value",
    });
  }

  if (errors.length === 0) {
    return {
      fromTimestamp: row.A as CogenerationPlanInput["fromTimestamp"],
      toTimestamp: row.B as CogenerationPlanInput["toTimestamp"],
      production: row.C === null ? 0 : Number(row.C),
      consumption: row.D === null ? 0 : Number(row.D),
    };
  }

  return errors;
}

export function getMissingHours(
  inputs: CogenerationPlanInput[]
): MissingHourRange[] {
  if (inputs.length === 0) {
    return [];
  }

  const sortedInputs = inputs.sort((a, b) =>
    parseISO(a.fromTimestamp).getTime() - parseISO(b.fromTimestamp).getTime()
  );

  const missingHours: MissingHourRange[] = [];

  for (let i = 0; i < sortedInputs.length - 1; i++) {
    const current = sortedInputs[i]!;
    const next = sortedInputs[i + 1]!;

    const hoursDifference = differenceInHours(
      parseISO(next.fromTimestamp),
      parseISO(current.toTimestamp)
    );

    if (hoursDifference > 0) {
      let lastToTimestamp = parseISO(current.toTimestamp);
      for (let j = 0; j < hoursDifference; j++) {
        const newFromTimestamp = lastToTimestamp;
        const newToTimestamp = addHours(newFromTimestamp, 1);
        missingHours.push({
          fromTimestamp: formatISO(newFromTimestamp),
          toTimestamp: formatISO(newToTimestamp),
        });
        lastToTimestamp = newToTimestamp;
      }
    }
  }

  return missingHours;
}
