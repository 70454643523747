import React from "react";
import classnames from "classnames";

import { Translate } from "src/translations/translate";
import Notification from "/src/modules/Notification";

import AccessControl from "src/components/accessControl";
import { NotMobile } from "src/components/responsive";
import { ErrorBoundary } from "src/components/error";
import WeatherMap from "./weatherMap";
import DashboardNews from "./dashboardNews";
import EnergySummary from "./energySummary";

import styles from "./dashboard.module.scss";
import globalStyles from "src/styles/global.module.scss";
import { useGetCurrentUser } from "src/modules/userProvider";
import { PriceTrends } from "src/modules/dashboard/priceTrends/priceTrends";
import { LatestSpotPrices } from "./latestSpotPrices/latestSpotPrices";
import { PageLayout } from "src/components/pageLayout/pageLayout";

const Dashboard = () => {
  const { firstName, selectedOrganizationConnection } = useGetCurrentUser();

  return (
    <>
      <PageLayout
        title={
          <>
            <Translate>Welcome</Translate>, {firstName}!
          </>
        }
      >
        <Notification />
        <ErrorBoundary>
          <div className={styles.main}>
            <LatestSpotPrices />

            <div className={styles.column}>
              <PriceTrends />
              <AccessControl
                restriction={{
                  type: "Knowledge",
                  permission: "Read",
                }}
              >
                <DashboardNews />
              </AccessControl>
            </div>
          </div>

          <AccessControl
            restriction={{
              type: "Energy",
              permission: "Read",
            }}
          >
            <h2
              className={classnames(
                globalStyles.sectionTitle,
                styles.sectionTitle,
              )}
            >
              <Translate>Consumption and Production</Translate>
            </h2>
            <div>
              <ErrorBoundary>
                <EnergySummary
                  key={selectedOrganizationConnection.organization.id}
                />
              </ErrorBoundary>
            </div>
          </AccessControl>

          <div className={styles.main}>
            <NotMobile>
              <div>
                <h2
                  className={classnames(
                    globalStyles.sectionTitle,
                    styles.sectionTitle,
                  )}
                >
                  <Translate>Weather</Translate>
                </h2>
                <ErrorBoundary>
                  <WeatherMap />
                </ErrorBoundary>
              </div>
            </NotMobile>
          </div>
        </ErrorBoundary>
      </PageLayout>
    </>
  );
};

export default Dashboard;
