import { useState } from "react";
import { useTranslation } from "src/translations/translationProvider";
import Button, { BUTTON_STATE } from "src/components/button";
import ControlHeader from "src/components/controlHeader/controlHeader";
import globalStyles from "src/styles/global.module.scss";
import styles from "./cogenerationPlan.module.scss";

import { ExcelValidationErrors } from "src/components/excelValidationError/excelValidationError";
import { getMissingHours, validateParsedCogenerationPlanExcel } from "src/modules/app/customerdata/incomingDataPlans/validateCogenerationPlan";
import { parseExcel } from "src/utilities/parseExcel";
import { useMutation, useQuery } from "@apollo/client";
import { DataGroup, GetDataGroupsDocument, SaveCogenerationPlansDocument} from "src/graphql/graphql";
import { useNotifications } from "src/modules/notifications/notificationsProvider";
import { CogenerationData, CogenerationPlanGraph, CogenerationPlanInput, MissingHourRange, ParsedExcelError,  } from "./cogenerationPlanModel";
import LoadingSpinner from "src/components/loadingSpinner";
import classNames from "classnames";
import { CogenerationPlanChart } from "./chart/cogenerationPlanChart";
import { Select } from "@mantine/core";
import { DownloadIncomingDataTemplate } from "./downloadIncomingDataTemplate/downloadIncomingDataTemplate";


export function CogenerationPlan({ organizationId } : { organizationId: string }) {
  const [plans, setPlans] = useState<CogenerationPlanInput[]>([]);
  const [plansGraph, setPlansGraph] = useState<CogenerationPlanGraph[]>([])
  const [missingHours, setMissingHours] = useState<MissingHourRange[]>([]);
  const [errors, setErrors] = useState<ParsedExcelError[]>();
  const [canSave, setCanSave] = useState(false);
  const [buttonState, setButtonState] = useState<(typeof BUTTON_STATE)[keyof typeof BUTTON_STATE]>(BUTTON_STATE.DEFAULT);
  const [dataGroupId, setDataGroupId] = useState(0);
  const [cogenerationDataGroups, setcogenerationDataGroups] = useState<DataGroup[]>();

  const { translate } = useTranslation();
  const { showNotification } = useNotifications();
  const [saveCogenerationPlanMutation] = useMutation(SaveCogenerationPlansDocument, {
    onCompleted: () => {
      setButtonState(BUTTON_STATE.SUCCESS);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
      showNotification(translate("Successfully saved cogeneration plans"));
    },
    onError: () => {
      setButtonState(BUTTON_STATE.FAIL);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
      showNotification(
        translate("Saving cogeneration plans failed"),
      );
    },
  });
  const { loading, error, data } = useQuery(GetDataGroupsDocument, {
    variables: {
      organizationId: organizationId,
    },
    onCompleted: (data) => {
      const cogenerationGroups =
      data?.organizationDataGroups?.dataGroups?.filter(
        (group) => group.type === "Cogeneration"
      ) || [];

      setcogenerationDataGroups(cogenerationGroups);

      if (cogenerationGroups.length > 0 && cogenerationGroups[0]) {
        setDataGroupId(cogenerationGroups[0].id);
      }
    },
  });

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error || !data) {
    console.log(error); // eslint-disable-line no-console
    return <div>{translate("UnexpectedError")}</div>;
  }

  const selectDataGroups = (cogenerationDataGroups ?? []).map((group) => ({
    value: group.id.toString(),
    label: group.name,
  }));

  if (selectDataGroups.length === 0) {
    return (
      <h3 className={classNames(styles.heading, styles.section)}>
        {translate("No datagroups found")}
      </h3>
    );
  }
  
  const handleSave = () => {
    setButtonState(BUTTON_STATE.LOADING);

    if (!plans) {
      return;
    }

    saveCogenerationPlanMutation({
      variables: {
        organizationId,
        dataGroupId,
        plans: plans,
      },
    })
  };

  const handleFileChange = (file: File) => {
    parseExcel<CogenerationData>(file, (result) => {
      const { errors, values } = validateParsedCogenerationPlanExcel(result);
      const missingHours = getMissingHours(values);
      const planForGraph = getPlanForGraph(values, missingHours);
  
      setCanSave(false);
      setErrors([]);
      setMissingHours([]);
  
      if (errors.length > 0) {
        setErrors(errors);
      } else {
        setPlansGraph(planForGraph);
        
        if (missingHours.length > 0) {
          setMissingHours(missingHours);
        } else {
          setPlans(values);
          setCanSave(true);
        }
      }
    });
  };

  const getPlanForGraph = (
    inputs: CogenerationPlanInput[],
    missingHourRanges: MissingHourRange[]
  ): CogenerationPlanGraph[] => {
    const missingData = missingHourRanges.map((range) => ({
      fromTimestamp: range.fromTimestamp,
      toTimestamp: range.toTimestamp,
      production: null,
      consumption: null,
    }));
  
    return [...inputs, ...missingData].sort(
      (a, b) => new Date(a.fromTimestamp).getTime() - new Date(b.fromTimestamp).getTime()
    );
  };

  return (
    <div>
      <Select
          label={translate("Select Data Group")}
          data={selectDataGroups}
          value={dataGroupId?.toString() || ""}
          onChange={(value) => setDataGroupId(Number(value))}
          clearable={false}
          mb={"lg"}
        />
      <header className={globalStyles.subTitle}>
        {translate("CogenerationPlanDownloadInstructions")}
      </header>
      <ControlHeader className={styles.controlHeader}>
        <div className={styles.fileUpload}>
          <input
            type="file"
            name="cogenerationPlan"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (!file) {
                return;
              }
              handleFileChange(file);
            }}
          ></input>
        </div>
        
      </ControlHeader>
      <DownloadIncomingDataTemplate
          organizationId={organizationId}
          groupId={dataGroupId}
        />
      <main className={styles.verification}>
        <ExcelValidationErrors errors={errors || []} missingHours={missingHours || []} />
        <div className={globalStyles.sectionTitleLarge}>
          <CogenerationPlanChart
            cogenerationPlans={plansGraph}
            className={styles.chart}
          />
        </div>
      </main>
      <Button
        state={buttonState} 
        disabled={!canSave} 
        onClick={handleSave} 
        small>
          {translate("Save")}
      </Button>
    </div>
  );
}
